import {Component, ElementRef, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LoadingService} from './block/interceptor/loading.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import 'core-js/es/string';
import 'core-js/es/array';
import 'core-js/es/map';
import {JhiEventManager} from 'ng-jhipster';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {} from './app.constants'
import Swal from 'sweetalert2';
import {NavigationEnd, Router} from '@angular/router';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  @ViewChild('template')
  public template: ElementRef;
  modalRef: BsModalRef;
  @Output() eventManager = new JhiEventManager;

  constructor(
    private loadingService: LoadingService,
    private deviceService: DeviceDetectorService,
    private modalService: BsModalService,
    public router: Router,
  ) {

  }

  ngOnInit() {
    const browser = this.deviceService.getDeviceInfo();
    const version = parseInt(browser.browser_version, 10);

    console.log(browser.browser);
    console.log(browser.browser_version);
    console.log(version);
    let ok = false;

    if (browser.browser.toLowerCase() === 'chrome' || browser.browser === 'Chrome') {
      // ok = version >= 45;
      ok = version >= 45;
    } else if (browser.browser.toLowerCase() === 'firefox') {
      ok = version >= 38;
    } else if (browser.browser.toLowerCase() === 'safari' || browser.browser === 'Safari') {
      ok = version >= 9;
    } else if (browser.browser.toLowerCase() === 'opera') {
      ok = version >= 30;
    } else if (browser.browser.toLowerCase() === 'edge') {
      ok = version >= 12;
    } else if (browser.browser.toLowerCase() === 'ms-edge-chromium') {
      ok = true;
    } else if (browser.browser.toLowerCase() === 'ie') {
      ok = version >= 11;
    }


    this.loadExternalScript("https://www.googletagmanager.com/gtag/js?id=G-0XQZF8HJ31");
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', 'G-0XQZF8HJ31',
            {
              'page_path': event.urlAfterRedirects,
              'page_title': event.urlAfterRedirects
            }
        );
      }
    });



    console.log(ok);
    if(!ok) {
      this.openModal();
    }
  }

  openModal() {
    Swal.fire({
      title: 'Il browser che stai utilizzando non è supportato\n' +
          '    si consiglia di utilizzare le ultime versioni di Chrome, Firefox, Safari, Edge.',
      confirmButtonText: 'OK',
      icon: 'warning',
      confirmButtonColor:'#F6030A',
    })
    //this.modalRef = this.modalService.show(this.template, {class: 'modal-sm', backdrop: true, ignoreBackdropClick: true,});
  }

  isLoading() {
    return this.loadingService.isLoading();
  }

  public loadExternalScript(url: string) {
    const head = <HTMLDivElement> document.head;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    head.appendChild(script);
  }

}
